import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import CarListing from "../pages/CarListing";
import CarDetails from "../pages/CarDetails";
import NotFound from "../pages/NotFound";
import Contact from "../pages/Contact";
import LiveAuction from "../pages/LiveAuction";
import Dashboard from "../components/Dashboard/Dashboard";
import Login from "../components/Dashboard/Login";
import AddCarForSaleForm from "../components/Dashboard/AddCarForSaleForm";
import AddCarForAuctionForm from "../components/Dashboard/AddCarForAuctionForm";
import PrivateRoute from "../components/PrivateRoute";
import AuctionDetails from "../pages/AuctionDetails";
import AddCarForTransitForm from "../components/Dashboard/AddCarForTransitForm";
import TransitDetails from "../pages/TransitDetails";
import InTransit from "../pages/InTransit";
import SoldCars from "../pages/SoldCars";


const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/cars" element={<CarListing />} />
      <Route path="/cars/:slug" element={<CarDetails />} /> 
      <Route path="/in-transit" element={<InTransit />} />
      <Route path="/in-transit/:slug" element={<TransitDetails />} />
      <Route path="/live-auction" element={<LiveAuction />} />
      <Route path="/live-auction/:slug" element={<AuctionDetails />} />
      <Route path="/sold" element={<SoldCars />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/login" element={<Login />} />
      <Route element={<PrivateRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/add-car-sale" element={<AddCarForSaleForm />} />
        <Route path="/dashboard/add-car-auction" element={<AddCarForAuctionForm />} />
        <Route path="/dashboard/add-transit-car" element={<AddCarForTransitForm />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routers;
