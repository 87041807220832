import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col, Tab, Tabs, Carousel } from "react-bootstrap";
import Helmet from "../components/Helmet/Helmet";

const AuctionDetails = () => {
  const { slug } = useParams();
  const [car, setCar] = useState(null);
  const [index, setIndex] = useState(0);
  const [imgError, setImgError] = useState({});
  const [timeRemaining, setTimeRemaining] = useState(0); // Time remaining in ms

  const createSlug = (name) =>
    name
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");

  useEffect(() => {
    const fetchCarDetails = async () => {
      try {
        const response = await axios.get(
          `https://active-car-backend.onrender.com/api/cars-for-auction`,
          { withCredentials: true }
        );
        const carDetails = response.data.find(
          (car) => createSlug(car.name) === slug
        );
        if (carDetails) {
          setCar(carDetails);
          const now = new Date().getTime();
          const end = new Date(carDetails.endDate).getTime();
          setTimeRemaining(end - now);
        }
      } catch (error) {
        console.error("Error fetching auction car details:", error);
      }
    };

    fetchCarDetails();
  }, [slug]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining((prev) => Math.max(prev - 1000, 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleSelect = (selectedIndex) => setIndex(selectedIndex);

  const handleImageError = (index) => {
    setImgError((prev) => ({ ...prev, [index]: true }));
  };

  const formatTime = (ms) => {
    const totalSeconds = Math.floor(ms / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${days}d ${String(hours).padStart(2, "0")}h ${String(
      minutes
    ).padStart(2, "0")}m ${String(seconds).padStart(2, "0")}s`;
  };

  if (!car) return <p>Loading...</p>;

  const whatsappNumber = "+923458309990";
  const message = `I'm interested in the auction car: ${car.name}.`;

  return (
    <Helmet title={car.name}>
      <section>
        <Container>
          <Row className="mb-4">
            <Col lg="8" md="12">
              <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                interval={null}
              >
                {car.images.map((img, idx) => (
                  <Carousel.Item key={idx}>
                    <img
                      className="d-block w-100"
                      src={imgError[idx] || !img ? "/placeholder.png" : img}
                      alt={`Slide ${idx + 1}`}
                      style={{
                        height: window.innerWidth < 768 ? "300px" : "500px",
                        objectFit: "cover",
                      }}
                      onError={() => handleImageError(idx)}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>

              <div
                style={{
                  display: "flex",
                  overflowX: "auto",
                  marginTop: "10px",
                }}
              >
                {car.images.map((img, idx) => (
                  <img
                    key={idx}
                    src={imgError[idx] || !img ? "/placeholder.png" : img}
                    alt={`Thumbnail ${idx + 1}`}
                    style={{
                      maxWidth: "100px",
                      maxHeight: "60px",
                      objectFit: "cover",
                      cursor: "pointer",
                      marginRight: "10px",
                    }}
                    onClick={() => setIndex(idx)}
                    onError={() => handleImageError(idx)}
                  />
                ))}
              </div>
            </Col>

            <Col lg="4" md="12">
              <div className="mb-4">
                <h2 className="section__title">{car.name}</h2>
                <h6 className="rent__price fw-bold fs-4">Rs. {car.price}</h6>
                <p className="section__description">
                  End Date: {new Date(car.endDate).toLocaleString()}
                </p>
                <p className="section__description">
                  Time Remaining: {formatTime(timeRemaining)}
                </p>
              </div>

              <Tabs
                defaultActiveKey="basic"
                id="car-detail-tabs"
                className="mb-3"
              >
                <Tab eventKey="basic" title="Details" className="pt-3">
                  <div className="details-grid row g-3">
                    {Object.entries(car.basic).map(([key, value]) => (
                      <div key={key} className="detail-item col-6">
                        <strong>{key}:</strong> {value}
                      </div>
                    ))}
                  </div>
                </Tab>

                <Tab
                  eventKey="damage"
                  title="Damage Condition"
                  className="pt-3"
                >
                  <div className="details-grid row g-3">
                    {Object.entries(car.damage).map(([key, value]) => (
                      <div key={key} className="detail-item col-6">
                        <strong>{key}:</strong> {value.toString()}
                      </div>
                    ))}
                  </div>
                </Tab>

                <Tab eventKey="remarks" title="Remarks" className="pt-3">
                  <div className="details-grid row g-3">
                    <div className="detail-item col-12">
                      <p>{car.remarks || "No remarks available."}</p>
                    </div>
                  </div>
                </Tab>
              </Tabs>

              <div className="d-flex justify-content-center mt-4">
                <a
                  href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
                    message
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="car__item-btn car__btn-get-now">
                    Contact Now
                  </button>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default AuctionDetails;
