import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { fetchCarsForAuction } from "../api"; // Import the fetch function
import { Link } from "react-router-dom";

const LiveAuction = () => {
  const [carData, setCarData] = useState([]);
  const [imgError, setImgError] = useState({}); // Track which images have errors

  const createSlug = (name) => {
    return name
      .toLowerCase()
      .replace(/ /g, '-') // Replace spaces with hyphens
      .replace(/[^\w-]+/g, ''); // Remove non-word characters
  };

  useEffect(() => {
    const getAuctionCars = async () => {
      try {
        const data = await fetchCarsForAuction();
        console.log(data);  // Log to see if you get valid Cloudinary URLs
        setCarData(data);
      } catch (error) {
        console.error("Error fetching auction cars:", error);
      }
    };
    getAuctionCars();
  }, []);

  const handleImageError = (index) => {
    setImgError((prev) => ({ ...prev, [index]: true })); // Set the specific image as errored
  };

  return (
    <Helmet title="Live Auction">
      <CommonSection title="Live Auction" />
      <section>
        <Container>
          <Row>
            <Col lg="12">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Damage</th>
                    <th>End Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {carData.map((item, index) => (
                    <tr key={item._id}>
                      <td>
                        <img
                          src={
                            imgError[index] || !item.images[0] 
                              ? "/placeholder.png" 
                              : item.images[0]
                          }
                          alt={item.name}
                          style={{ width: "100px", height: "auto", objectFit: "cover" }}
                          onError={() => handleImageError(index)}
                        />
                      </td>
                      <td>{item.name}</td>
                      <td>Rs. {item.price}</td>
                      <td>{item.damage?.areaDamage || "N/A"}</td>
                      <td>{new Date(item.endDate).toLocaleDateString()}</td>
                      <td>
                        <Link to={`/live-auction/${createSlug(item.name)}`}>
                          <button className="car__item-btn car__btn-get-now">View More</button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default LiveAuction;
