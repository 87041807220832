import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LogoutButton from "./LogoutButton";

const AddCarForAuctionForm = () => {
  const [editIndex, setEditIndex] = useState(-1);
  const [cars, setCars] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalImages, setModalImages] = useState([]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth?.token);
  const makeOptions = ["Suzuki", "Honda", "Daihatsu", "Nissan", "Toyota", "Mitsubishi"];

  useEffect(() => {
    if (!token) {
      toast.error("Please login, your session seems to be logged out.");
      navigate("/login");
    }
  }, [token, navigate]);

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const response = await axios.get(
          "https://active-car-backend.onrender.com/api/cars-for-auction",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        setCars(response.data);
      } catch (error) {
        toast.error("Error fetching cars!");
        console.error("Error fetching cars:", error);
      }
    };
    if (token) {
      fetchCars();
    }
  }, [token]);

  const [formData, setFormData] = useState({
    images: [],
    rawImages: [],
    name: "",
    price: "",
    endDate: "",
    basic: {
      make: "",
      model: "",
      variant: "",
      condition: "",
      fuel: "",
      mileage: "",
      color: "",
      type: "",
    },
    damage: {
      areaDamage: "",
      driveCondition: "",
      engineAssessment: "",
      airbagAssessment: "",
      suspension: "",
      exterior: "",
    },
    remarks: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split(".");
    if (keys.length === 2) {
      setFormData((prev) => ({
        ...prev,
        [keys[0]]: {
          ...prev[keys[0]],
          [keys[1]]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    const newPreviewUrls = newFiles.map((file) => URL.createObjectURL(file));

    setFormData((prev) => ({
      ...prev,
      rawImages: [...prev.rawImages, ...newFiles],
      images: [...prev.images, ...newPreviewUrls],
    }));
  };

  useEffect(() => {
    return () => {
      formData.images.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [formData.images]);

  const uploadImagesToBackend = async (rawImages) => {
    try {
      const formData = new FormData();
      rawImages.forEach((image) => {
        formData.append("images", image);
      });

      const response = await axios.post(
        "https://active-car-backend.onrender.com/api/upload-auction",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      return response.data.images;
    } catch (error) {
      toast.error("Error uploading images!");
      console.error("Error uploading images to backend:", error);
      throw error;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let imageUrls = formData.images;
    if (formData.rawImages.length > 0) {
      imageUrls = await uploadImagesToBackend(formData.rawImages);
    }

    const submitFormData = new FormData();
    submitFormData.append("name", formData.name);
    submitFormData.append("price", formData.price);
    submitFormData.append("endDate", formData.endDate);
    Object.entries(formData.basic).forEach(([key, value]) => {
      submitFormData.append(`basic.${key}`, value);
    });
    Object.entries(formData.damage).forEach(([key, value]) => {
      submitFormData.append(`damage.${key}`, value);
    });
    submitFormData.append("remarks", formData.remarks);

    imageUrls.forEach((url) => {
      submitFormData.append("images", url);
    });

    const endpoint = formData.id
      ? `https://active-car-backend.onrender.com/api/cars-for-auction/${formData.id}`
      : "https://active-car-backend.onrender.com/api/cars-for-auction";
    const method = formData.id ? "put" : "post";

    const response = await axios({
      method: method,
      url: endpoint,
      data: submitFormData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    });

    if (response.data && response.data.car) {
      const updatedCars = formData.id
        ? cars.map((car) => (car._id === formData.id ? response.data.car : car))
        : [...cars, response.data.car];
      setCars(updatedCars);
      toast.success("Car updated successfully!");
      resetForm();
    }
  };

  const handleEdit = (index) => {
    const carToEdit = cars[index];
    if (!carToEdit) return;

    setFormData({
      id: carToEdit._id || null,
      images: carToEdit.images || [],
      rawImages: [],
      name: carToEdit.name || "",
      price: carToEdit.price || "",
      endDate: carToEdit.endDate
        ? new Date(carToEdit.endDate).toISOString().split("T")[0]
        : "",
      basic: {
        make: carToEdit.basic?.make || "",
        model: carToEdit.basic?.model || "",
        variant: carToEdit.basic?.variant || "",
        condition: carToEdit.basic?.condition || "",
        fuel: carToEdit.basic?.fuel || "",
        mileage: carToEdit.basic?.mileage || "",
        color: carToEdit.basic?.color || "",
        type: carToEdit.basic?.type || "",
      },
      damage: {
        areaDamage: carToEdit.damage?.areaDamage || "",
        driveCondition: carToEdit.damage?.driveCondition || "",
        engineAssessment: carToEdit.damage?.engineAssessment || "",
        airbagAssessment: carToEdit.damage?.airbagAssessment || "",
        suspension: carToEdit.damage?.suspension || "",
        exterior: carToEdit.damage?.exterior || "",
      },
      remarks: carToEdit.remarks || "",
    });
    setEditIndex(index);
  };

  const handleDelete = async (id) => {
    confirmAction("Are you sure you want to delete this car?", async () => {
      try {
        await axios.delete(
          `https://active-car-backend.onrender.com/api/cars-for-auction/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        setCars((prev) => prev.filter((car) => car._id !== id));
        toast.success("Car deleted successfully!");
      } catch (error) {
        toast.error("Error deleting car!");
        console.error("Error deleting car:", error);
      }
    });
  };

  const resetForm = () => {
    setFormData({
      images: [],
      rawImages: [],
      name: "",
      price: "",
      endDate: "",
      basic: {
        make: "",
        model: "",
        variant: "",
        condition: "",
        fuel: "",
        mileage: "",
        color: "",
        type: "",
      },
      damage: {
        areaDamage: "",
        driveCondition: "",
        engineAssessment: "",
        airbagAssessment: "",
        suspension: "",
        exterior: "",
      },
      remarks: "",
    });
    setEditIndex(-1);
  };

  const confirmAction = (message, onConfirm) => {
    toast(
      ({ closeToast }) => (
        <div>
          <p>{message}</p>
          <button
            onClick={() => {
              closeToast();
              onConfirm();
            }}
            style={{
              marginRight: "10px",
              padding: "5px 10px",
              background: "green",
              color: "white",
              border: "none",
              cursor: "pointer",
            }}
          >
            Confirm
          </button>
          <button
            onClick={closeToast}
            style={{
              padding: "5px 10px",
              background: "red",
              color: "white",
              border: "none",
              cursor: "pointer",
            }}
          >
            Cancel
          </button>
        </div>
      ),
      { autoClose: false }
    );
  };

  const renderImagePreview = (images) => {
    if (!images || images.length === 0) {
      return null;
    }

    const firstImage = images[0];
    const remainingCount = images.length - 1;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => openModal(images)}
      >
        <img
          src={firstImage}
          alt="Preview"
          style={{
            width: "100px",
            height: "100px",
            objectFit: "cover",
            marginRight: "10px",
          }}
        />
        {remainingCount > 0 && (
          <span style={{ fontSize: "14px", color: "#555" }}>
            + {remainingCount} more
          </span>
        )}
      </div>
    );
  };
  const openModal = () => {
    setModalImages([...formData.images]);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleDeleteImage = (index) => {
    const filteredImages = formData.images.filter((_, i) => i !== index);
    const filteredRawImages = formData.rawImages.filter((_, i) => i !== index);

    setFormData((prev) => ({
      ...prev,
      images: filteredImages,
      rawImages: filteredRawImages,
    }));

    setModalImages(filteredImages);
  };

  const renderModal = () => (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        padding: "10px",
      }}
    >
      <div
        style={{
          position: "relative",
          padding: "20px",
          background: "#fff",
          borderRadius: "12px",
          width: "90%",
          maxWidth: "600px",
          maxHeight: "80%",
          overflowY: "auto",
          boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
        }}
      >
        <button
          onClick={closeModal}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
            background: "transparent",
            border: "none",
            fontSize: "24px",
            color: "#000",
            zIndex: "1000",
          }}
        >
          ✖
        </button>
        {modalImages.map((img, index) => (
          <div
            key={index}
            style={{ marginBottom: "10px", position: "relative" }}
          >
            <img
              src={img}
              alt={`Car ${index}`}
              style={{ width: "100%", borderRadius: "8px" }}
            />
            <button
              onClick={() => handleDeleteImage(index)}
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                padding: "5px 10px",
                background: "#fff",
                color: "#000",
                border: "none",
                cursor: "pointer",
              }}
            >
              <i className="ri-delete-bin-7-line"></i>
            </button>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="container" style={styles.container}>
      <LogoutButton />
      <h2>Add Car for Auction</h2>

      <form onSubmit={handleSubmit} style={styles.formContainer}>
        <div>
          <label style={styles.label}>Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            style={styles.input}
          />
        </div>
        <div>
          <label style={styles.label}>Price:</label>
          <input
            type="text"
            name="price"
            value={formData.price}
            onChange={handleChange}
            style={styles.input}
          />
        </div>
        <div>
          <label style={styles.label}>End Date:</label>
          <input
            type="date"
            name="endDate"
            value={formData.endDate ? formData.endDate.split("T")[0] : ""}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, endDate: e.target.value }))
            }
            style={styles.input}
          />
        </div>
        <div>
          <label style={styles.label}>Images:</label>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            style={styles.input}
          />
          <div style={{ marginTop: "10px" }}>
            {formData.images.length > 0 && renderImagePreview(formData.images)}
          </div>
        </div>
        {["basic", "damage"].map((section) => (
    <fieldset key={section} style={{ gridColumn: "1 / -1" }}>
      <legend>
        {section.charAt(0).toUpperCase() + section.slice(1)}
      </legend>
      {Object.entries(formData[section]).map(([key, value]) => (
        <div key={key}>
          <label style={styles.label}>
            {key.charAt(0).toUpperCase() +
              key.slice(1).replace(/([A-Z])/g, " $1")}
            :
          </label>
          {key === "make" ? (
            <select
              name={`${section}.${key}`}
              value={value}
              onChange={handleChange}
              style={styles.input}
            >
              <option value="">Select Make</option>
              {makeOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          ) : (
            <input
              type="text"
              name={`${section}.${key}`}
              value={value}
              onChange={handleChange}
              style={styles.input}
            />
          )}
        </div>
      ))}
    </fieldset>
  ))}
        <div style={{ gridColumn: "1 / -1" }}>
          <label style={styles.label}>Remarks:</label>
          <textarea
            name="remarks"
            value={formData.remarks}
            onChange={handleChange}
            style={styles.textarea}
          />
        </div>
        <button type="submit" style={styles.button}>
          Submit
        </button>
      </form>
      <div style={styles.carGrid}>
        {cars.map((car, index) => (
          <div key={index} style={styles.carCard}>
            <div style={{ position: "absolute", right: "2px", top: "-15px" }}>
              <button
                onClick={() => handleEdit(index)}
                style={{ ...styles.iconButton, marginRight: "20px" }}
              >
                <i className="ri-edit-line"></i>
              </button>
              <button
                onClick={() => handleDelete(car._id)}
                style={styles.iconButton}
              >
                <i className="ri-delete-bin-7-line"></i>
              </button>
            </div>
            <div style={styles.carContent}>
              <div style={styles.carImage}>
                {car.images && car.images.length > 0 ? (
                  <img
                    src={car.images[0]}
                    alt="Car"
                    style={{
                      width: "100%",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  "No Image"
                )}
              </div>
              <div style={styles.carDetails}>
                <p>Name: {car.name}</p>
                <p>Price: {car.price}</p>
                <p>End Date: {car.endDate}</p>
                <p>Remarks: {car.remarks}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {showModal && renderModal()}
      <ToastContainer />
    </div>
  );
};
const styles = {
  container: {
    position: "relative",
    padding: "20px",
  },
  formContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
    gap: "15px",
    maxWidth: "1000px",
    margin: "auto",
    padding: "20px",
    background: "#fff",
    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
    borderRadius: "8px",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  label: {
    fontWeight: "600",
    marginBottom: "5px",
  },
  button: {
    width: "100%",
    padding: "10px",
    marginTop: "20px",
    backgroundColor: "#0056b3",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  textarea: {
    width: "100%",
    minHeight: "100px",
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    margin: "10px 0",
  },
  carGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(180px, 1fr))",
    gap: "15px",
    alignItems: "start",
    justifyItems: "center",
  },
  carCard: {
    width: "100%",
    maxWidth: "300px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px",
    padding: "15px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    background: "#f9f9f9",
    position: "relative",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  },
  carContent: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "start",
    marginTop: "20px",
  },
  carDetails: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginLeft: "20px",
  },
  carImage: {
    width: "100%",
    height: "180px",
    objectFit: "cover", // Ensures the image covers the area while maintaining aspect ratio
    borderRadius: "4px",
  },
  iconButton: {
    cursor: "pointer",
    fontSize: "16px",
    padding: "5px",
    border: "none",
    borderRadius: "4px",
    background: "transparent",
    position: "absolute",
    top: "10px",
    right: "10px",
  },
};
export default AddCarForAuctionForm;
