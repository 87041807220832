import React, { useEffect, useState } from "react";
import HeroSlider from "../components/UI/HeroSlider";
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import AboutSection from "../components/UI/AboutSection";
import BecomeDriverSection from "../components/UI/BecomeDriverSection";
import Testimonial from "../components/UI/Testimonial";
import { fetchCarsForSale } from "../api";
import { Link } from "react-router-dom";

const Home = () => {
  const [carData, setCarData] = useState([]);

  const createSlug = (name) => {
    return name.toLowerCase().replace(/ /g, "-").replace(/[^\w-]+/g, "");
  };

  // Fetch cars for sale from backend
  useEffect(() => {
    const getCarsForSale = async () => {
      try {
        const data = await fetchCarsForSale();
        const availableCars = data.filter((car) => !car.isSold);
        setCarData(availableCars);
      } catch (error) {
        console.error("Error fetching cars for sale:", error);
      }
    };

    getCarsForSale();
  }, []);

  return (
    <Helmet title="Home">
      {/* ============= Hero Section =========== */}
      <section className="p-0 hero__slider-section">
        <HeroSlider />
      </section>

      {/* =========== Car Offer Section ============= */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="text-center mb-5">
              <h6 className="section__subtitle">Come with</h6>
              <h2 className="section__title">Cars Available for Sale</h2>
            </Col>

            {/* Car Item Grid */}
            {carData.map((item) => (
              <Col lg="4" md="6" sm="6" className="mb-4" key={item._id}>
                <div
                  className="car__item"
                  style={{
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    overflow: "hidden",
                    transition: "transform 0.3s ease",
                    padding: "10px",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = "translateY(-5px)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "translateY(0)";
                  }}
                >
                  <div
                    className="car__img"
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      height: "280px",
                    }}
                  >
                    <img
                      src={item.images[0]}
                      alt={item.name}
                      className="w-100"
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                  <div className="car__item-content mt-4">
                    <h4
                      className="section__title text-center"
                      style={{ fontSize: "22px" }}
                    >
                      {item.name}
                    </h4>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <h6 className="rent__price">Rs. {item.price}</h6>
                      <span className="model__info">
                        {item.details.model}
                      </span>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                      <Link to={`/cars/${createSlug(item.name)}`}>
                        <button className="car__item-btn car__btn-get-now">
                          View More
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* =========== About Section ================ */}
      <AboutSection />

      {/* =========== Become a Driver Section ============ */}
      <BecomeDriverSection />

      {/* =========== Testimonial Section =========== */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-4 text-center">
              <h6 className="section__subtitle">Our clients say</h6>
              <h2 className="section__title">
                Why You Should Use Active Car
              </h2>
            </Col>
            <Testimonial />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Home;
