import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { checkTokenExpiration, logoutUser } from '../redux/features/auth/authSlice'; // Import the new action

const PrivateRoute = () => {
  const token = useSelector((state) => state.auth?.token); // Access token from auth state
  const dispatch = useDispatch();

  // Check if the token is expired
  dispatch(checkTokenExpiration());

  if (!token) {
    // If token is null after expiration check, logout and redirect to login
    dispatch(logoutUser());
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default PrivateRoute;
