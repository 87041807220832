import React, { useEffect, useState, useMemo } from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { fetchCarsForTransit } from "../api";
import { Link } from "react-router-dom";

const InTransit = () => {
  const [carData, setCarData] = useState([]);
  const [sortOption, setSortOption] = useState("select");

  const createSlug = (name) => {
    return name
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  useEffect(() => {
    const getCarsForTransit = async () => {
      try {
        const data = await fetchCarsForTransit();
        setCarData(data);
      } catch (error) {
        console.error("Error fetching cars for sale:", error);
      }
    };

    getCarsForTransit();
  }, []);

  const sortedCarData = useMemo(() => {
    if (sortOption === "low") {
      return [...carData].sort((a, b) => a.price - b.price);
    } else if (sortOption === "high") {
      return [...carData].sort((a, b) => b.price - a.price);
    }
    return carData;
  }, [sortOption, carData]);

  const handleSort = (e) => {
    setSortOption(e.target.value);
  };

  return (
    <Helmet title="In-Transit Unit Cars">
      <CommonSection title="In-Transit Unit Cars" />
      <section>
        <Container>
          <Row>
            <Col lg="12">
              <div className="d-flex align-items-center gap-3 mb-5">
                <span className="d-flex align-items-center gap-2">
                  <i className="ri-sort-asc"></i> Sort By
                </span>
                <select onChange={handleSort} value={sortOption}>
                  <option value="select">Select</option>
                  <option value="low">Low to High</option>
                  <option value="high">High to Low</option>
                </select>
              </div>
            </Col>

            {sortedCarData.map((item) => (
              <Col lg="4" md="6" sm="6" className="mb-4" key={item._id}>
                <div
                  className="car__item"
                  style={{
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    overflow: "hidden",
                    transition: "transform 0.3s ease",
                    padding: "10px",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = "translateY(-5px)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "translateY(0)";
                  }}
                >
                  <div
                    className="car__img"
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      height: "280px",
                    }}
                  >
                    <img
                      src={item.images[0]} 
                      alt={item.name}
                      loading="lazy"
                      className="w-100"
                      style={{
                        objectFit: "cover", 
                        width: "100%",
                        height: "100%",
                      }}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "/path/to/fallback-image.png"; 
                      }}
                    />
                  </div>
                  <div className="car__item-content mt-4">
                    <h4
                      className="section__title text-center"
                      style={{ fontSize: "22px" }}
                    >
                      {item.name}
                    </h4>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <h6 className="rent__price">Rs. {item.price}</h6>
                      <span className="model__info">{item.details.model}</span>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                      <Link to={`/in-transit/${createSlug(item.name)}`}>
                        <button className="car__item-btn car__btn-get-now">
                          View More
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default InTransit;
