import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post('https://active-car-backend.onrender.com/api/login', userData);
      
      const { token, expiresIn, user } = response.data; 
      const expirationTime = new Date().getTime() + expiresIn * 1000; 
      
      localStorage.setItem('token', token); 
      localStorage.setItem('expirationTime', expirationTime); 

      return { token, user, expirationTime };  
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : { message: 'Network error' });
    }
  }
);

const initialState = {
  user: null,
  token: localStorage.getItem('token') || null,
  expirationTime: localStorage.getItem('expirationTime') || null,
  status: 'idle', 
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutUser(state) {
      localStorage.removeItem('token'); 
      localStorage.removeItem('expirationTime'); 
      state.token = null;
      state.user = null;
      state.expirationTime = null;
    },
    checkTokenExpiration(state) {
      const now = new Date().getTime();
      const expirationTime = localStorage.getItem('expirationTime');
      if (expirationTime && now > expirationTime) {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationTime');
        state.token = null;
        state.user = null;
        state.expirationTime = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.expirationTime = action.payload.expirationTime;
        state.status = 'succeeded';
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload?.message || 'Failed to login';
      });
  },
});

export const { logoutUser, checkTokenExpiration } = authSlice.actions;

export default authSlice.reducer;
