import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-section.css";
import aboutImg from "../../assets/all-images/about-img.webp";

const AboutSection = ({ aboutClass }) => {
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage"
          ? { marginTop: "0px" }
          : { marginTop: "20px" }
      }
    >
      <Container>
        <Row>
          <Col lg="7" md="7">
            <div className="about__section-content">
              <h4 className="section__subtitle">Get to know us</h4>
              <h2 className="section__title">
                Trusted & Leading in Car Dealership Services
              </h2>
              <p className="section__description">
                Active Car is proud to be a trusted and leading provider in car
                dealership services. Our commitment to quality and customer
                satisfaction sets us apart in the industry, ensuring a seamless
                and enjoyable experience for every client.
              </p>

              <div className="about__section-items">
                <div className="about__section-item">
                  <p className="section__description d-flex align-items-center gap-2">
                    <i className="ri-checkbox-circle-line"></i> Specializing in importing cars directly from Japan.
                  </p>
                </div>
                <div className="about__section-item">
                  <p className="section__description d-flex align-items-center gap-2">
                    <i className="ri-checkbox-circle-line"></i> High-quality vehicles available at competitive prices.
                  </p>
                </div>
                <div className="about__section-item">
                  <p className="section__description d-flex align-items-center gap-2">
                    <i className="ri-checkbox-circle-line"></i> Car parts available on demand.
                  </p>
                </div>
                <div className="about__section-item">
                  <p className="section__description d-flex align-items-center gap-2">
                    <i className="ri-checkbox-circle-line"></i> Easy bidding process with timely updates on your bids.
                  </p>
                </div>
              </div>
            </div>
          </Col>

          <Col lg="5" md="5">
            <div className="about__img">
              <img src={aboutImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
