import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col, Tab, Tabs, Carousel } from "react-bootstrap";
import Helmet from "../components/Helmet/Helmet";

const CarDetails = () => {
  const { slug } = useParams();
  const [car, setCar] = useState(null);
  const [index, setIndex] = useState(0);
  const [imgError, setImgError] = useState({});

  const createSlug = (name) =>
    name
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");

  useEffect(() => {
    const fetchCarDetails = async () => {
      try {
        const response = await axios.get(
          `https://active-car-backend.onrender.com/api/cars-for-transit`,
          { withCredentials: true }
        );
        const carDetails = response.data.find(
          (car) => createSlug(car.name) === slug
        );
        setCar(carDetails);
      } catch (error) {
        console.error("Error fetching transit car details:", error);
      }
    };

    fetchCarDetails();
  }, [slug]);

  const handleSelect = (selectedIndex) => setIndex(selectedIndex);

  const handleImageError = (index) => {
    setImgError((prev) => ({ ...prev, [index]: true }));
  };

  if (!car) return <p>Loading...</p>;

  return (
    <Helmet title={car.name}>
      <section>
        <Container>
          <Row className="mb-4">
            <Col lg="8" md="12">
              <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                interval={null}
              >
                {car.images.map((img, idx) => (
                  <Carousel.Item key={idx}>
                    <img
                      className="d-block w-100"
                      src={imgError[idx] || !img ? "/placeholder.png" : img}
                      alt={`Slide ${idx + 1}`}
                      style={{
                        height: window.innerWidth < 768 ? "300px" : "500px",
                        objectFit: "cover",
                      }}
                      onError={() => handleImageError(idx)}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>

              <div
                style={{
                  display: "flex",
                  overflowX: "auto",
                  marginTop: "10px",
                }}
              >
                {car.images.map((img, idx) => (
                  <img
                    key={idx}
                    src={imgError[idx] || !img ? "/placeholder.png" : img}
                    alt={`Thumbnail ${idx + 1}`}
                    style={{
                      maxWidth: "100px",
                      maxHeight: "60px",
                      objectFit: "cover",
                      cursor: "pointer",
                      marginRight: "10px",
                    }}
                    onClick={() => setIndex(idx)}
                    onError={() => handleImageError(idx)}
                  />
                ))}
              </div>
            </Col>

            <Col lg="4" md="12">
              <div className="mb-4">
                <h2 className="section__title">{car.name}</h2>
                <h6 className="rent__price fw-bold fs-4">Rs. {car.price}</h6>
              </div>

              <Tabs
                defaultActiveKey="details"
                id="car-detail-tabs"
                className="mb-3"
              >
                <Tab eventKey="details" title="Details" className="pt-3">
                  <div className="details-grid row g-3">
                    {Object.entries(car.details).map(([key, value]) => (
                      <div key={key} className="detail-item col-6">
                        <strong>{key}:</strong>{" "}
                        {key === "displacement" ? `${value} cc` : value}
                      </div>
                    ))}
                  </div>
                </Tab>

                <Tab eventKey="options" title="Options" className="pt-3">
                  <div className="details-grid row g-3">
                    {Object.entries(car.options).map(([key, value]) => (
                      <div key={key} className="detail-item col-6">
                        <strong>{key}:</strong> {value ? "Yes" : "No"}
                      </div>
                    ))}
                  </div>
                </Tab>

                <Tab
                  eventKey="exteriorCondition"
                  title="Exterior"
                  className="pt-3"
                >
                  <div className="details-grid row g-3">
                    {Object.entries(car.exteriorCondition).map(
                      ([key, value]) => (
                        <div key={key} className="detail-item col-6">
                          <strong>{key}:</strong> {value || "N/A"}
                        </div>
                      )
                    )}
                  </div>
                </Tab>

                <Tab eventKey="note" title="Note" className="pt-3">
                  <div className="details-grid row g-3">
                    {Array.isArray(car.note) ? (
                      car.note.map((line, index) => (
                        <div key={index} className="detail-item col-12">
                          <p>{line}</p>
                        </div>
                      ))
                    ) : (
                      <div className="detail-item col-12">
                        <p>{car.note}</p>
                      </div>
                    )}
                  </div>
                </Tab>
              </Tabs>

              <div className="d-flex justify-content-center mt-4">
                <a
                  href={`https://wa.me/923458309990?text=${encodeURIComponent(
                    `I'm interested in the in-transit unit car: ${car.name}.`
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="car__item-btn car__btn-get-now">
                    Contact Now
                  </button>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default CarDetails;
