import axios from "axios";

const API_URL = "https://active-car-backend.onrender.com/api";

export const fetchCarsForSale = async () => {
  try {
    const response = await axios.get(`${API_URL}/cars-for-sale`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching cars for sale:", error);
    throw error;
  }
};
export const fetchCarsForTransit = async () => {
  try {
    const response = await axios.get(`${API_URL}/cars-for-transit`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching cars for transit:", error);
    throw error;
  }
};

export const fetchCarsForAuction = async () => {
  try {
    const response = await axios.get(`${API_URL}/cars-for-auction`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching auction cars:", error);
    throw error;
  }
};
