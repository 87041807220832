import React from "react";
import Slider from "react-slick";
import "../../styles/testimonial.css";

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div className="testimonial py-4 px-3">
        <p className="section__description">
          "I had an amazing experience with Rawalpindi Car Imports. The bidding
          process was smooth, and the car I imported from Japan was exactly as 
          described. Highly recommend their services!"
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">

          <div>
            <h6 className="mb-0 mt-3">Ahmed Khan</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          "Rawalpindi Car Imports made my dream of owning a Japanese car come 
          true. Their customer service is top-notch, and they handled everything 
          professionally. Will definitely use them again!"
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">

          <div>
            <h6 className="mb-0 mt-3">Fatima Ali</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          "I was a bit skeptical about bidding for a car online, but Rawalpindi 
          Car Imports exceeded my expectations. The car arrived on time and in 
          perfect condition. Great job!"
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">

          <div>
            <h6 className="mb-0 mt-3">Usman Tariq</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          "The team at Rawalpindi Car Imports guided me through the entire process. 
          They helped me find the perfect car at an unbeatable price. Couldn't be happier 
          with my purchase!"
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">

          <div>
            <h6 className="mb-0 mt-3">Sara Malik</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default Testimonial;
