import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  carsForSale: [],
  carsForAuction: [],
  carsForTransit: [],
  status: "idle",
  error: null,
  loadingCount: 0, // Track loading actions
};

const carSlice = createSlice({
  name: "cars",
  initialState,
  reducers: {
    // ----- Sale Car Actions -----
    addCarForSaleStart: (state) => {
      state.loadingCount += 1;
    },
    addCarForSaleSuccess: (state, action) => {
      state.carsForSale.push(action.payload);
      state.loadingCount -= 1;
    },
    editCarForSaleStart: (state) => {
      state.loadingCount += 1;
    },
    editCarForSaleSuccess: (state, action) => {
      const index = state.carsForSale.findIndex(
        (car) => car.id === action.payload.id
      );
      if (index !== -1) {
        state.carsForSale[index] = action.payload;
      }
      state.loadingCount -= 1;
    },
    deleteCarForSaleStart: (state) => {
      state.loadingCount += 1;
    },
    deleteCarForSaleSuccess: (state, action) => {
      state.carsForSale = state.carsForSale.filter(
        (car) => car.id !== action.payload
      );
      state.loadingCount -= 1;
    },

    // ----- Auction Car Actions -----
    addCarForAuctionStart: (state) => {
      state.loadingCount += 1;
    },
    addCarForAuctionSuccess: (state, action) => {
      state.carsForAuction.push(action.payload);
      state.loadingCount -= 1;
    },
    editCarForAuctionStart: (state) => {
      state.loadingCount += 1;
    },
    editCarForAuctionSuccess: (state, action) => {
      const index = state.carsForAuction.findIndex(
        (car) => car.id === action.payload.id
      );
      if (index !== -1) {
        state.carsForAuction[index] = action.payload;
      }
      state.loadingCount -= 1;
    },
    deleteCarForAuctionStart: (state) => {
      state.loadingCount += 1;
    },
    deleteCarForAuctionSuccess: (state, action) => {
      state.carsForAuction = state.carsForAuction.filter(
        (car) => car.id !== action.payload
      );
      state.loadingCount -= 1;
    },

    // ----- Transit Car Actions -----
    addCarForTransitStart: (state) => {
      state.loadingCount += 1;
    },
    addCarForTransitSuccess: (state, action) => {
      state.carsForTransit.push(action.payload);
      state.loadingCount -= 1;
    },
    editCarForTransitStart: (state) => {
      state.loadingCount += 1;
    },
    editCarForTransitSuccess: (state, action) => {
      const index = state.carsForTransit.findIndex(
        (car) => car.id === action.payload.id
      );
      if (index !== -1) {
        state.carsForTransit[index] = action.payload;
      }
      state.loadingCount -= 1;
    },
    deleteCarForTransitStart: (state) => {
      state.loadingCount += 1;
    },
    deleteCarForTransitSuccess: (state, action) => {
      state.carsForTransit = state.carsForTransit.filter(
        (car) => car.id !== action.payload
      );
      state.loadingCount -= 1;
    },
  },
});

export const {
  // Sale Car Actions
  addCarForSaleStart,
  addCarForSaleSuccess,
  editCarForSaleStart,
  editCarForSaleSuccess,
  deleteCarForSaleStart,
  deleteCarForSaleSuccess,

  // Auction Car Actions
  addCarForAuctionStart,
  addCarForAuctionSuccess,
  editCarForAuctionStart,
  editCarForAuctionSuccess,
  deleteCarForAuctionStart,
  deleteCarForAuctionSuccess,

  // Transit Car Actions
  addCarForTransitStart,
  addCarForTransitSuccess,
  editCarForTransitStart,
  editCarForTransitSuccess,
  deleteCarForTransitStart,
  deleteCarForTransitSuccess,
} = carSlice.actions;

export default carSlice.reducer;
