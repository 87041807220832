import React from "react";
import { Link } from "react-router-dom";
import LogoutButton from "./LogoutButton";

const Dashboard = () => {
  return (
    <div style={styles.dashboardContainer}>
      <LogoutButton />
      <h1 style={styles.header}>Dashboard</h1>
      <div style={styles.cardContainer}>
        <Link to="/dashboard/add-car-sale" style={styles.link}>
          <div style={{ ...styles.card, ...styles.sale }}>
            <i className="ri-car-line" style={styles.icon}></i>
            Add Car for Sale
          </div>
        </Link>

        <Link to="/dashboard/add-car-auction" style={styles.link}>
          <div style={{ ...styles.card, ...styles.auction }}>
            <i className="ri-auction-line" style={styles.icon}></i>
            Add Car for Auction
          </div>
        </Link>

        <Link to="/dashboard/add-transit-car" style={styles.link}>
          <div style={{ ...styles.card, ...styles.inTransit }}>
            <i className="ri-ship-line" style={styles.icon}></i>
            In-Transit Units
          </div>
        </Link>

        <div style={{ ...styles.card, ...styles.disabled }}>
          <i className="ri-tools-line" style={styles.icon}></i>
          Add Parts (Disabled)
        </div>
      </div>
    </div>
  );
};

const styles = {
  dashboardContainer: {
    padding: "20px",
    textAlign: "center",
    position: "relative",
  },
  header: {
    marginBottom: "20px",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    gap: "20px",
  },
  card: {
    width: "200px",
    height: "200px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "18px",
    color: "white",
    cursor: "pointer",
    padding: "15px",
  },
  sale: {
    background: "lightblue",
  },
  auction: {
    background: "lightgreen",
  },
  inTransit: {
    background: "lightsalmon",
  },
  disabled: {
    background: "gray",
    opacity: 0.5,
    cursor: "not-allowed",
  },
  icon: {
    fontSize: "48px",
    marginBottom: "10px",
  },
  link: {
    textDecoration: "none",
  },
};

export default Dashboard;
